import React, { useState } from "react";
import { navigate } from "gatsby";
import Login from "../../containers/Developer/Login";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import {
  isLoggedIn,
  getUserType,
  setLoginTypeCookies,
} from "../../common/utils/cookie-utils";
import Logout from "../../common/components/Logout/Logout";
const DevLoginPage = () => {
  const userType = getUserType();
  // setLoginTypeCookies("developerLogin");
  if (!isLoggedIn()) return <Login />;
  else if (userType != undefined) {
    if (userType == 1) {
      navigate("/app/dashboard/bp");
    } else {
      navigate("/app/dashboard");
    }
  } else Logout({ hardReload: false, routeTo: "/" });
  return null;
};
export default DevLoginPage;
